import { setToasterState } from '../../../common/toaster/services/toasterAction';
import { GetRequest, PostRequest, PutRequest } from '../../../plugins/axios';
import { User } from '../../common/model/User';
import jwt_decode from 'jwt-decode';
import store from '../../../store/store';
const changePasswordUrl = `${process.env.REACT_APP_API_BASE_URL}users/changePassword`;
const authenticationUrl = process.env.REACT_APP_API_BASE_URL;
const userUrl = process.env.REACT_APP_API_BASE_URL + 'users';

export const loginSuccess = (payload: any) => {
  return {
    type: 'LOGIN_SUCCESS',
    payload: payload,
  };
};
export const instituteInfoSuccess = (payload: any) => {
  return {
    type: 'GET_INSTITUTE_INFO',
    payload: payload,
  };
};

export const logoutSuccess = () => {
  return {
    type: 'LOGOUT',
  };
};

export const loginFailed = () => {
  return {
    type: 'LOGIN_FAIL',
  };
};

export const changeUser = (payload: User) => {
  return {
    type: 'CHANGE_USER',
    payload: payload,
  };
};

export const setLoading = (payload: boolean) => {
  return {
    type: 'LOADING',
    payload: payload,
  };
};

export const changeOwnPassword = (data: any) => (dispatch: any) => {
  const userID = store.getState().auth.user._id;
  if (userID) {
    PutRequest(`${changePasswordUrl}/${userID}`, data, {})
      .then((res) => {
        dispatch(
          setToasterState({
            appear: true,
            title: 'success',
            name: 'Success',
            message: 'Password Change Success',
          })
        );
      })
      .catch((err) => {
        dispatch(
          setToasterState({
            appear: true,
            title: 'Error',
            name: 'Change Password',
            message: 'Change Password Failed!',
          })
        );
      });
  }
};

const addImageInUser = (image: string) => (dispatch: any) => {
  const user: any = localStorage.getItem('user');
  const token: any = localStorage.getItem('token');
  let userObj = JSON.parse(user);
  userObj = {
    ...userObj,
    image: image,
  };
  dispatch(loginSuccess({ token: token, user: userObj }));
};

const editOwnName = (name: string) => (dispatch: any) => {
  const user: any = localStorage.getItem('user');
  const token: any = localStorage.getItem('token');
  let userObj = JSON.parse(user);
  userObj = {
    ...userObj,
    name: name,
  };
  dispatch(loginSuccess({ token: token, user: userObj }));
};

export const changeUserPicture = (payload: any) => (dispatch: any) => {
  const userID = store.getState().auth.user._id;
  if (userID) {
    const data = new FormData();
    data.append('image', payload);
    PutRequest(`${userUrl}/changePicture/${userID}`, data, {})
      .then((res) => {
        dispatch(addImageInUser(res.data.image));
        dispatch(
          setToasterState({
            appear: true,
            title: 'success',
            name: 'Success',
            message: 'Change Picture Success',
          })
        );
      })
      .catch((err) => {
        console.log(err);

        dispatch(
          setToasterState({
            appear: true,
            title: 'Error',
            name: 'Change Picture',
            message: 'Change Picture Failed!',
          })
        );
      });
  }
};

export const editOwnDetails = (payload: any) => (dispatch: any) => {
  const userID = store.getState().auth.user._id;

  if (userID) {
    PutRequest(`${userUrl}/${userID}`, payload, {})
      .then((res) => {
        dispatch(editOwnName(res.data.name));
        dispatch(
          setToasterState({
            appear: true,
            title: 'success',
            name: 'Success',
            message: 'Updating User Details Success',
          })
        );
      })
      .catch((err) => {
        console.log(err);

        dispatch(
          setToasterState({
            appear: true,
            title: 'Error',
            name: 'Change Picture',
            message: 'Change Picture Failed!',
          })
        );
      });
  }
};

export const addUsers =
  (payload: any = {}) =>
  (dispatch: any) => {
    PostRequest(
      authenticationUrl + 'signup',
      {
        email: payload.email,
        password: payload.password,
        phone: payload.phone,
      },
      {}
    )
      .then((response: any) => {
        dispatch(
          setToasterState({
            appear: true,
            title: 'success',
            name: 'User Registered',
            message: 'The provided information was saved',
          })
        );
      })
      .catch((error: any) => {
        console.error(error);
        dispatch(loginFailed());
        dispatch(
          setToasterState({
            appear: true,
            title: 'error',
            name: 'Authentication Error',
            message: 'Data was not saved',
          })
        );
      });
  };
export const getInstituteInfo =
  (payload: any = {}) =>
  (dispatch: any) => {
    if (payload) {
      GetRequest(userUrl + `/getInstituteInfo/${payload}`, {}, {})
        .then((response: any) => {
          dispatch(instituteInfoSuccess(response.data));
        })
        .catch((errors: any) => {
          dispatch(
            setToasterState({
              appear: true,
              title: 'error',
              name: 'Invalid Data',
              message: 'Cannot Get Institute Info',
            })
          );
        });
    }
  };
export const authenticate =
  (payload: any = {}) =>
  (dispatch: any) => {
    dispatch(setLoading(true));
    PostRequest(
      authenticationUrl + 'signin',
      {
        email: payload.email,
        password: payload.password,
      },
      {}
    )
      .then((response: any) => {
        dispatch(loginSuccess(response.data));
        if (response.data.user.created_by) {
          dispatch(getInstituteInfo(response.data.user.created_by));
        }
      })
      .catch((errors: any) => {
        dispatch(setLoading(false));
        dispatch(loginFailed());

        dispatch(
          setToasterState({
            appear: true,
            title: 'error',
            name: 'Authentication Error',
            message: errors.response?.data?.error || 'Invalid Credentials',
          })
        );
      });
  };
export const authenticateGoogle =
  (payload: any = {}) =>
  (dispatch: any) => {
    dispatch(setLoading(true));
    PostRequest(authenticationUrl + 'signin/google', payload, {})
      .then((response: any) => {
        dispatch(loginSuccess(response.data));
        if (response.data.user.created_by) {
          dispatch(getInstituteInfo(response.data.user.created_by));
        }
      })
      .catch((errors: any) => {
        dispatch(setLoading(false));
        dispatch(loginFailed());

        dispatch(
          setToasterState({
            appear: true,
            title: 'error',
            name: 'Authentication Error',
            message: errors.response?.data?.error || 'Invalid Credentials',
          })
        );
      });
  };

export const reauthenticate = () => (dispatch: any) => {
  try {
    let token = localStorage.getItem('token');
    let user: any = localStorage.getItem('user');
    let institute: any = localStorage.getItem('institute');
    if (token && token !== 'token') {
      let decode: any = jwt_decode(token);
      if (decode?.exp > Math.floor(Date.now()/1000)) {
        let userObject = JSON.parse(user);
        dispatch(loginSuccess({ token: token, user: userObject }));
        dispatch(instituteInfoSuccess(JSON.parse(institute)));
      } else {
        dispatch(
          setToasterState({
            appear: true,
            title: 'error',
            name: 'Token Expired',
            message: 'Please Login again',
          })
        );
        logout()(dispatch);
      }
    }
  } catch (e) {
    dispatch(
      setToasterState({
        appear: true,
        title: 'error',
        name: 'Token Expired',
        message: 'Please Login again',
      })
    );
    logout()(dispatch);
  }
};

export const logout = () => (dispatch: any) => {
  localStorage.removeItem('user');
  localStorage.removeItem('type');
  localStorage.removeItem('token');
  localStorage.removeItem('institute');
  dispatch(logoutSuccess());
};

export const getClientNameAddress = (hostname: string) => (dispatch: any) => {
  console.log(hostname);
};
